// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-community-support-index-js": () => import("./../../../src/pages/community-support/index.js" /* webpackChunkName: "component---src-pages-community-support-index-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-patient-info-js": () => import("./../../../src/pages/en/patient-info.js" /* webpackChunkName: "component---src-pages-en-patient-info-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-patient-info-js": () => import("./../../../src/pages/fr/patient-info.js" /* webpackChunkName: "component---src-pages-fr-patient-info-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourvisioncare-program-index-js": () => import("./../../../src/pages/ourvisioncare-program/index.js" /* webpackChunkName: "component---src-pages-ourvisioncare-program-index-js" */),
  "component---src-pages-patient-info-index-js": () => import("./../../../src/pages/patient-info/index.js" /* webpackChunkName: "component---src-pages-patient-info-index-js" */),
  "component---src-pages-risk-management-measures-index-js": () => import("./../../../src/pages/risk-management-measures/index.js" /* webpackChunkName: "component---src-pages-risk-management-measures-index-js" */),
  "component---src-pages-rmt-index-js": () => import("./../../../src/pages/rmt/index.js" /* webpackChunkName: "component---src-pages-rmt-index-js" */)
}

